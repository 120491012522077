<script setup lang="ts">
import { withTrailingSlash } from 'ufo'
import type { VerticalNavigationLink } from '#ui/types'

const props = defineProps<{
  expanded?: boolean
  items: (VerticalNavigationLink & { children: VerticalNavigationLink[], icon: any })[]
}>()

const route = useRoute()

const currentOpenMenu = computed(() => {
  return props.items.find(i => (i.children || []).some(c => isCurrent(c.to as string)))
})

function isCurrent(path: string) {
  // we just care about the first path segment matching
  const pathSegments = (path || '').split('/').filter(Boolean)
  const currPathSegments = (route.path || '').split('/').filter(Boolean)
  return withTrailingSlash(currPathSegments[0]) === withTrailingSlash(pathSegments[0])
}

const buttonUi = {
  padding: { '2xs': 'p-2' },
  gap: { '2xs': 'gap-x-2' },
  variant: {
    solid: 'hover:bg-forgd-primary-500 bg-forgd-primary-900',
  },
}

const popoverUi = {
  ring: 'ring-forgd-primary-800',
  arrow: {
    base: 'invisible before:visible before:block before:rotate-45 before:z-[-1] before:w-2 before:h-2',
    ring: 'before:ring-1 before:ring-forgd-primary-900 dark:before:ring-gray-900',
    rounded: 'before:rounded-sm',
    background: 'before:bg-forgd-primary-900',
    shadow: 'before:shadow',
    placement: 'before:translate-x-[15px]',
  },
}
</script>

<template>
  <ul class="space-y-2">
    <li v-for="(item, key) in items" :key="key">
      <template v-if="!expanded">
        <UiPopover v-if="item.label || item.children" mode="hover" :popper="{ placement: 'right', offsetDistance: 15, arrow: true }" :ui="popoverUi">
          <UButton size="2xs" :to="item.children ? undefined : item.to" active-class="bg-forgd-primary-500" :ui="buttonUi" v-on="item.on ?? {}" class="relative">
            <UIcon v-if="typeof item.icon === 'string'" :name="item.icon" class="w-5 h-5 text--bgd-200" />
            <component :is="item.icon" v-else :expanded="expanded" class="w-5 h-5 text-forgd-bgd-200" />
            <svg v-if="item.label === currentOpenMenu?.label" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none" class="absolute top-1 right-1">
              <circle cx="4" cy="4" r="4" fill="#2D73FF" />
            </svg>
          </UButton>
          <template #panel>
            <div class="text-left px-2 py-5 bg-forgd-primary-900 text-white text-xs">
              <ULink :to="item.to" class="px-3 whitespace-nowrap font-bold" v-on="item.on ?? {}">
                {{ item.label }}
              </ULink>
              <template v-if="item.children">
                <UDivider class="opacity-20 my-2" />
                <UVerticalNavigation :links="item.children" :ui="{ size: 'text-xs', inactive: 'text-forged-bgd-200 hover:before:bg-forgd-primary-500 hover:text-white' }" />
              </template>
            </div>
          </template>
        </UiPopover>
        <UButton v-else size="2xs" :loading="item.loading" :to="item.children ? undefined : item.to" active-class="bg-forgd-primary-500" :ui="buttonUi">
          <UIcon v-if="typeof item.icon === 'string'" :name="item.icon" class="w-5 h-5 text--bgd-200" />
          <component :is="item.icon" v-else :expanded="expanded" class="w-5 h-5 text-forgd-bgd-200" />
        </UButton>
      </template>
      <template v-else>
        <UAccordion v-if="item.children" :key="currentOpenMenu?.label" unmount :items="[item]" :default-open="item.label === currentOpenMenu?.label">
          <template #default="{ item: child, open }">
            <UButton size="2xs" :to="child.children ? undefined : child.to" active-class="bg-forgd-primary-500" :active="isCurrent(child.to)" :ui="buttonUi">
              <UIcon v-if="typeof child.icon === 'string'" :name="child.icon" class="w-5 h-5 text--bgd-200" />
              <component :is="child.icon" v-else :expanded="expanded" class="w-5 h-5 text-forgd-bgd-200" />
              <span v-if="child.label" class="text-xs text-white font-normal">
                {{ child.label }}
              </span>
              <template #trailing>
                <UIcon
                  name="i-heroicons-chevron-down-20-solid"
                  class="w-5 h-5 ms-auto transform transition-transform duration-200 text-white"
                  :class="[open && 'rotate-180']"
                />
              </template>
            </UButton>
          </template>
          <template #item="{ item: child }">
            <ul class="space-y-1">
              <li v-for="(subChild, k) in child.children" :key="k">
                <UButton :external="subChild.external" :target="subChild.external ? '_blank' : undefined" :trailing-icon="subChild.external ? 'i-heroicons-arrow-up-right' : undefined" :loading="subChild.loading" class="w-full" size="2xs" :to="subChild.children ? undefined : subChild.to" active-class="active-item" :active="isCurrent(subChild.to)" :ui="buttonUi" v-on="subChild.on ?? {}">
                  <div class="flex items-center justify-between w-full">
                    <div class="flex items-center gap-2 w-full">
                      <UIcon v-if="typeof subChild.icon === 'string'" :name="subChild.icon" class="w-5 h-5 text--bgd-200" />
                      <component :is="subChild.icon" v-else :expanded="expanded" class="w-5 h-5 text-forgd-bgd-200" />
                      <div class="font-normal">
                        {{ subChild.label }}
                      </div>
                    </div>
                    <svg v-if="isCurrent(subChild.to)" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                      <circle cx="4" cy="4" r="4" fill="#2D73FF" />
                    </svg>
                  </div>
                </UButton>
              </li>
            </ul>
          </template>
        </UAccordion>
        <UButton v-else size="2xs" :external="item.external" :target="item.external ? '_blank' : undefined" :loading="item.loading" class="w-full" :to="item.children ? undefined : item.to" active-class="bg-forgd-primary-500" :ui="buttonUi" v-on="item.on ?? {}">
          <div class="flex items-center justify-between w-full">
            <div class="flex items-center gap-2 w-full">
              <UIcon v-if="typeof item.icon === 'string'" :name="item.icon" class="w-5 h-5 text--bgd-200" />
              <component :is="item.icon" v-else :expanded="expanded" class="w-5 h-5 text-forgd-bgd-200" />
              <div v-if="item.label" class="font-normal">
                {{ item.label }}
              </div>
            </div>
            <svg v-if="isCurrent(item.to)" xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none" class="mr-1">
              <circle cx="4" cy="4" r="4" fill="#2D73FF" />
            </svg>
          </div>
        </UButton>
      </template>
    </li>
  </ul>
</template>
