<script setup lang="ts">
defineProps<{ expanded: boolean }>()
const auth = useAuth()

const isLoggingOut = ref(false)

const links = computed(() => {
  return [
    [
      {
        icon: 'i-heroicons-home',
        to: auth.dashboardPath,
        label: 'Home',
      },
      {
        icon: resolveComponent('IconToolsAndServices'),
        to: '/tools-and-services',
        label: 'Tools & services',
        children: [
          { label: 'Token Designer', to: '/token-designer' },
          { label: 'Tokenomics', to: '/tokenomics' },
          // https://linear.app/forgd/issue/DEV-1494/growth-capital-can-still-be-accessed-via-the-sidebar-navigation
          { label: 'Growth Capital', to: 'https://docs.google.com/spreadsheets/d/1FWjsPxQALgSIOeXEmuvbRqb-PiWhZtWCvjGyQXegTFY/edit?gid=147303761#gid=147303761', external: true },
          { label: 'AutoDistribution', to: '/auto-distribution' },
          // https://linear.app/forgd/issue/DEV-1440/listing-and-liquidity-can-still-be-accessed-via-the-sidebar-navigation
          { label: 'Listing & Liquidity', to: 'https://docs.google.com/spreadsheets/d/1mwjO3l9oJ2Tsb2VUxmLScL-dRZXtJbm_i0-yf66kIq0/edit?gid=1983493144#gid=1983493144', external: true },
          // https://linear.app/forgd/issue/DEV-1439/business-intelligence-can-still-be-accessed-via-the-sidebar-navigation
          { label: 'Business Intelligence', to: '/partner-activity-lead/volume-liquidity-price-performance' },
          { label: 'AMM²', to: '/automated-market-making' },
          {
            label: 'Engage a Market Maker',
            to: '/engage-a-market-maker',
          },
          {
            label: 'Market Maker Monitoring',
            to: '/market-maker-monitoring/start',
          },
        ],
      },
      {
        icon: 'i-heroicons-academic-cap',
        label: 'Forgd Academy',
        children: [
          {
            to: '/pathfinder',
            label: 'Pathfinder',
          },
          {
            to: 'https://forgd.com/faq',
            label: 'Forgd Products FAQs',
            external: true,
          },
          {
            to: 'https://forgd.com/about',
            label: 'About Forgd',
            external: true,
          },
          {
            to: 'https://forgd.com/blog',
            label: 'Forgd Blog',
            external: true,
          },
        ],
      },
      {
        label: 'Schedule a Consultation',
        to: 'https://calendly.com/forgd/forgd-consultation',
        external: true,
        icon: 'i-heroicons-calendar-days',
      },
    ],
    [
      {
        icon: 'i-heroicons-information-circle',
        to: 'https://forgd.com/about',
        label: 'About Forgd',
        external: true,
      },
      {
        icon: 'i-heroicons-chat-bubble-oval-left-ellipsis',
        to: '/support',
        label: 'Support',
      },
      {
        icon: 'i-heroicons-cog-8-tooth',
        to: '/settings',
        label: 'Account Settings',
      },
      {
        icon: 'i-heroicons-arrow-right-start-on-rectangle',
        label: 'Logout',
        loading: isLoggingOut.value,
        on: {
          click: logout,
        },
      },
    ],
  ]
})

async function logout() {
  isLoggingOut.value = true
  await auth.logout()
  isLoggingOut.value = false
}
</script>

<template>
  <div
    class="flex-shrink-0 border-r bg-forgd-primary-900 font-display max-h-screen z-10 sticky top-0"
  >
    <nav
      class="flex flex-col h-full justify-between divide-y divide-gray-700 max-h-screen"
      :class="expanded ? 'w-[260px]' : 'w-[52px]'"
      aria-label="Site Navigation"
    >
      <div class="min-h-[80px] flex items-center justify-center w-full">
        <NuxtLink to="/">
          <IconForgd :expanded="expanded" />
        </NuxtLink>
      </div>
      <div class="py-4 flex-grow px-2 max-h-full overflow-y-auto min-height-[93px] dark-scrollbar">
        <AppMenuExpandableNavigationList
          :items="links[0]"
          :expanded="expanded"
        />
      </div>
      <div class="py-4 px-2 opacity-70">
        <AppMenuExpandableNavigationList
          :items="links[1]"
          :expanded="expanded"
        />
      </div>
    </nav>
  </div>
</template>

<style scoped>
.dark-scrollbar {
  /* should be skinny and use the theme's scrollbar color */
  scrollbar-width: thin;
  scrollbar-color: #2c3b52 transparent;
}
</style>
